export const PERMISSIONS = {
  // 企业资料
  companyInfo: 'companyInfo',
  // 企业认证
  companyCert: 'companyCert',
  // 子账号管理
  subAccount: 'subAccount',
  // 开发者中心
  APIManagement: 'APIManagement',
  // 修改手机
  modifyPhone: 'modifyPhone',
  // 修改密码
  modifyPassword: 'modifyPassword',
  // 发布岗位
  releaseJob: 'releaseJob',
  // 发起签约
  hroSign: 'hroSign',
  // 薪酬发放
  hroPayoff: 'hroPayoff',
  // 发布任务
  releaseTask: 'releaseTask',
  // 快速分包
  subpackage: 'subpackage',
  // 劳务报酬
  subpackageLabour: 'subpackageLabour',
  // 渠道模式
  crowdSourcingServicesChannel: 'crowdSourcingServicesChannel',
  // 众包服务tab
  crowdSourcingServices: 'crowdSourcingServices',
  // HRO服务tab
  hro: 'hro',
  // 招聘服务tab
  recruit: 'recruit'
}
