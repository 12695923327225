import { login, logout, getInfo } from '@/api/login'
import { register } from '@/api/register'
import { companyAuth, getCompanyAuth } from '@/api/companyAuth'
import { rolesType } from '@/config'
import Vue from 'vue'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { getSalaryEpibolicComInfo, getOrderInfo } from '@/api/salaryEpibolic'
import sentryTool from '@/sentry'
import { getRoles } from 'api/account'
import { toHash } from '@/utils'

const user = {
  state: {
    token: getToken() || '',
    userData: {},
    loginTipForPassword: false,
    userDataPromise: null, // 获取用户信息promise
    avatar: '',
    roles: [],
    unread: {
      notices: 0,
      questions: 0,
      jobApplys: 0
    },
    salaryEpibolicComInfo: {
      name: '',
      legalRepName: '',
      orgCode: '',
      legalRepIdNo: '',
      cardNo: '',
      bank: '',
      city: [],
      subbranch: '',
      cFourFactorsStatus: null,
      contractInfo: {}
    },
    salaryEpibolicOrderInfo: {
      employOrder: {},
      payrolls: {},
      certs: []
    },
    roleConfig: {
      list: [],
      hash: {}
    },
    companyAuthInfo: {}
  },
  mutations: {
    SET_ROLE_CONFIG: (state, roleConfig) => {
      state.roleConfig.list = roleConfig
      state.roleConfig.hash = toHash(roleConfig, 'roleId')
    },
    SET_SALARY_EPIBOLIC_ORDER_INFO: (state, salaryEpibolicOrderInfo) => {
      // state.salaryEpibolicOrderInfo = salaryEpibolicOrderInfo
      Vue.set(state, 'salaryEpibolicOrderInfo', salaryEpibolicOrderInfo)
    },
    SET_SALARY_EPIBOLIC_COM_INFO: (state, salaryEpibolicComInfo) => {
      Vue.set(state, 'salaryEpibolicComInfo', salaryEpibolicComInfo)
    },
    SET_COMPANY_AUTH_INFO: (state, companyAuthInfo) => {
      state.companyAuthInfo = companyAuthInfo
    },
    SET_LOGIN_TIP: (state, loginTipForPassword) => {
      state.loginTipForPassword = loginTipForPassword
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_UNREAD: (state, unread) => {
      state.unread = unread
    },
    CHANGE_UNREAD: (state, type) => {
      if (state.unread[type] > 0 && type === 'notices') {
        state.unread[type] -= 1
      } else {
        state.unread[type] = 0
      }
    },
    SET_USER_DATA: (state, userData) => {
      state.userData = userData
    },
    SET_USER_DATA_PROMISE: (state, userDataPromise) => {
      state.userDataPromise = userDataPromise
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      if (!roles) {
        roles = rolesType.DEFAULT
      }
      state.roles = [roles]
    },
    SET_AUTHSTATUS: (state, data) => {
      state.userData.authStatus = data.authStatus
      state.userData.userRole = data.hrStatus
    }
  },

  actions: {
    getCompanyAuthInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getCompanyAuth('').then(res => {
          commit('SET_COMPANY_AUTH_INFO', res.data)
          resolve()
        })
      })
    },
    getSalaryEpibolicComInfo({ commit, state }) { // 企业
      return new Promise((resolve, reject) => {
        getSalaryEpibolicComInfo().then(res => {
          commit('SET_SALARY_EPIBOLIC_COM_INFO', res.data)
          resolve()
        })
      })
    },
    getSalaryEpibolicOrderInfo({ commit, state }) { // 工单
      return new Promise((resolve, reject) => {
        getOrderInfo().then(res => {
          commit('SET_SALARY_EPIBOLIC_ORDER_INFO', res.data)
          resolve()
        })
      })
    },
    // 获取未读
    setUnread({ commit, dispatch, state }, unread) {
      return new Promise((resolve, reject) => {
        commit('SET_UNREAD', unread)
        resolve()
      })
    },
    // 读取通知
    readNotice({ commit, dispatch, state }, type) {
      return new Promise((resolve, reject) => {
        commit('CHANGE_UNREAD', type)
        resolve()
      })
    },
    // 登录
    Login({ commit, dispatch, state }, userInfo) {
      const userDataPromise = new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          const data = response.data
          setToken(data.token)
          commit('SET_USER_DATA', data)
          commit('SET_TOKEN', data.token)
          commit('SET_LOGIN_TIP', data.loginTipForPassword)
          // 设置前端上报用户信息
          sentryTool.setUser({
            id: data.comId,
            userName: data.companyName || '',
            phone: data.phone,
            token: state.token
          })
          sentryTool.setTag('phone', data.phone)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
      commit('SET_USER_DATA_PROMISE', userDataPromise)
      return userDataPromise
    },
    // 注册
    Register({ commit, dispatch, state }, userInfo) {
      return new Promise((resolve, reject) => {
        register(userInfo).then(response => {
          const data = response.data
          setToken(data.token)
          commit('SET_USER_DATA', data)
          commit('SET_ROLES', data.hrStatus)
          commit('SET_TOKEN', data.token)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    CompanyAuth({ commit, dispatch, state }, comInfo) {
      return new Promise((resolve, reject) => {
        companyAuth(comInfo).then(response => {
          dispatch('GetInfo')
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state, dispatch }) {
      const userDataPromise = new Promise((resolve, reject) => {
        getInfo().then(response => {
          const data = response.data
          commit('SET_USER_DATA', data)
          commit('SET_ROLES', data.roles || [])
          // 设置前端上报用户信息
          sentryTool.setUser({
            id: data.comId,
            userName: data.companyName || '',
            phone: data.phone,
            token: state.token
          })
          sentryTool.setTag('phone', data.phone)
          getRoles().then(({ data: rolesConfig }) => {
            commit('SET_ROLE_CONFIG', rolesConfig || [])
          })
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
      commit('SET_USER_DATA_PROMISE', userDataPromise)
      return userDataPromise
    },

    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_USER_DATA', {})
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_USER_DATA', {})
        commit('SET_ROLES', [])
        removeToken()
        resolve()
      })
    }
  }
}

export default user
