import request from '@/utils/request'
import { statusCode } from 'api/config'

/**
 * 登录
 */
export const login = data => request.post('/api/console/login', data, { headers: { ignoreCode: statusCode.IMG_CODE } })

/**
 * 获取用户信息
 */
export const getInfo = data => request.get('/api/console/get_login_info', { params: data })

/**
 * 登出
 */
export const logout = () => request.get('/api/console/logout')

/**
 * 是否在第一次输入拉取验证码
 */
export const getFirstImgCode = params => request.get('/api/console/code/getCaptchaStatus', { params, headers: { ignoreCode: statusCode.IMG_CODE } })
