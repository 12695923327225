<template>
      <el-submenu :index="menuIndex">
        <template slot="title">
          <span style="color: #DEDEDE;font-size: 16px" v-if="navStatus()">{{name}}</span>
        </template>
        <template v-for="(item,index) in routers">
          <template
            v-if="!item.hidden &&
                item[type.key] === type.value &&
                otherType(item) &&
                showingChildrenLen(item.children)">
            <el-menu-item
              :key="index"
              style="padding: 0"
              @click="backTop"
              :title="item.children[0].meta.title"
              v-if="showingChildrenLen(item.children)===1"
              :index="item.path + '/' + item.children[0].path">
              <template slot="title">
                <router-link
                  :to="{path:item.path + '/' + item.children[0].path}"
                  tag="div" class="route-item">
                  <svg-icon
                    v-show="routeActive.path() === item.path + '/' + item.children[0].path"
                    class="icon"
                    :icon-class="item.meta.icon+'-s'">
                  </svg-icon>
                  <svg-icon
                    class="icon"
                    v-show="routeActive.path() !== item.path + '/' + item.children[0].path"
                    :icon-class="item.meta.icon">
                  </svg-icon>
                  <template v-if="navStatus()">
                    {{ item.children[0].meta.title }}
                  </template>
                </router-link>
              </template>
            </el-menu-item>
            <template v-else>
              <el-submenu  :index="`${menuIndex}-${index}`"
                           :key="index"
              >
                <template slot="title">
                  <span :class=" item.meta.icon + ' icon'"></span>
                  <span>{{item.children[0].meta.title}}</span>
                </template>
                <el-submenu
                  :key="cIndex"
                  v-for="(child,cIndex) in item.children"
                  :index="item.path + '/' + child.path">
                  <el-menu-item
                    @click="backTop"
                  >
                    <router-link
                      :to="{path: item.path + '/' + child.path}"
                    >
                      {{child.name}}
                    </router-link>
                  </el-menu-item>
                </el-submenu>
              </el-submenu>
            </template>
          </template>
        </template>
      </el-submenu>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'sidebar',
  props: {
    type: {
      required: true,
      type: Object,
      default() {
        return {
          key: '', // 路由配置 type otherType
          value: ''
        }
      }
    },
    name: {
      required: true,
      type: String
    },
    menuIndex: {
      required: true,
      type: String
    }
  },
  inject: ['routeActive', 'navStatus', 'backTop'],
  computed: {
    ...mapGetters([
      'permission_routers',
      'userData'
    ])
  },
  data() {
    return {
      routers: []
    }
  },
  created() {
    this.setRouters()
  },
  methods: {
    setRouters() {
      this.routers = this.permission_routers
      this.routers.map(item => {
        item.children && item.children.map(i => {
          // 钱包菜单判断
          if (i.name === 'wallet' && !this.userData.useBurse) {
            i.hidden = true
          }
          return i
        })
        return item
      })
    },
    showingChildrenLen (children) {
      if (children) {
        const showingChildren = children.filter(item => {
          return !item.hidden
        })
        return showingChildren.length
      }

      return 0
    },
    selected (children) {
      const subPath = (children && children.meta && children.meta.subPath) || []
      // navKey参数控制菜单高亮的显示值为菜单根路由path，一般为该页面有对应多个菜单高亮展示
      // 如 众包任务管理进入任务详情， 账单管理进入任务详情
      const navKey = this.$router.currentRoute.query.navKey || children.path
      let curPath = this.$route.path
      if (curPath.indexOf('/') !== -1) {
        curPath = curPath.substr(curPath.lastIndexOf('/') + 1)
      }
      return children.path === curPath || (subPath.indexOf(curPath) !== -1 && children.path.indexOf(navKey) !== -1)
    },
    otherType (item) {
      let flag = false
      if (this.type.key === 'type' && !item.otherType) {
        flag = true
      } else if (this.type.key === 'otherType') {
        flag = true
      }
      return flag
    }
  },
  watch: {
    permission_routers() {
      this.setRouters()
    }
  }
}
</script>

<style scoped>

</style>
