import Layout from 'views/layout/Layout'

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * type: ''                       用于菜单渲染 路由类型 （招聘服务（），薪酬外包（salaryEpibolic），众包服务（crowdSourcingServices））
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    type: ''                     路由类型 （招聘服务（），薪酬外包（salaryEpibolic），众包服务（crowdSourcingServices））
    subPath: ['path']            菜单选中态  在父路由上配置 值为子路由 path
    parentPage: {                parentPage可以有多个面包屑配置
     defaultPage:[                  面包屑配置  数组对应 [1级页面，二级页面，三级页面...]
        {
          path: '/index.html',
          pageName: '热招岗位'
        },
        {
          path: '/heatJobDetails',
          pageName: '岗位详情',
          urlKey: ['jobId'],     当前路由可能会带的参数 值会从当前路由url上取
          query: {}
        }
      ]
      invoiceManagement: [        如需使用invoiceManagement面包屑配置 跳转时query.parentPageKey = invoiceManagement
       {                          例子：<router-link ,query:{navKey: 'invoiceManagement', parentPageKey: 'invoiceManagement'}}"></router-link>
                                                            navKey参数控制菜单高亮的显示值为菜单根路由path，一般为该页面有对应多个菜单高亮展示
                                                            例子 任务管理进入任务详情， 结算单管理进入任务详情
          path: '/invoiceManagement/invoiceManagement',
          pageName: '发票管理'
        },
        {
          path: '/invoiceManagement/invoiceApplicationRecord',
          pageName: '申请记录'
        }
      ]
    }
 }
 **/
const baseRouter = [
  {
    path: '/',
    redirect: '/login',
    meta: {
      isUnLogin: true
    },
    hidden: true
  },
  {
    path: '/login',
    component: () => import('views/login/index'),
    meta: {
      isUnLogin: true
    },
    hidden: true
  },
  {
    path: '/404',
    component: () => import('views/404'),
    hidden: true
  },
  {
    path: '/notice',
    component: Layout,
    type: 'notice',
    meta: { icon: 'message' },
    children: [
      {
        path: 'notice',
        name: 'notice',
        component: () => import('views/notice/index'),
        meta: {
          title: '我的消息',
          icon: 'message',
          type: 'notice'
        }
      }
    ]
  }
]

export const constantRouterMap = [
  ...baseRouter
]

export const asyncRouterMap = []

export const guards = {
  beforeEach(to, from, next) {
    next()
  }
}
