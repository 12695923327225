import * as ywWorkConfig from './ywWork'
import { PROJECT_NAME } from '@/config'

const config = {
  [PROJECT_NAME.ywWork]: {
    ...ywWorkConfig
  }
}
console.info(process.env.VUE_APP_PROJECT_NAME)

export const setting = {
  ...config[process.env.VUE_APP_PROJECT_NAME]
}
