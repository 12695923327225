<template>
  <img class="GImage" :src="src | isWebp(src)" alt="">
</template>

<script>
export default {
  name: 'GImage',
  props: {
    // views/index/home/img/icon-section-1_1.png
    gSrc: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      src: ''
    }
  },
  created() {
    if (this.gSrc) {
      import('@/' + this.gSrc).then(res => {
        this.src = res.default
      })
    }
  }
}

</script>

<style scoped>

</style>
