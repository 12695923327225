
const baseRouter = [
  {
    path: '/404',
    component: () => import('views/404'),
    hidden: true
  },
  {
    path: '/',
    component: () => import('@/page/ywqnHomePage/views/index/index'),
    hidden: true,
    redirect: '/index',
    type: 'recruit',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/page/ywqnHomePage/views/index/home/home'),
        meta: {
          type: 'index',
          isUnLogin: true // 是否不用登录
        },
        hidden: true
      },
      {
        path: '/employment',
        name: 'employment',
        component: () => import('@/page/ywqnHomePage/views/index/employment'),
        meta: {
          type: 'index',
          isUnLogin: true // 是否不用登录
        },
        hidden: true
      },
      {
        path: '/lingyongtongPage',
        name: 'lingyongtongPage',
        component: () => import('@/page/ywqnHomePage/views/index/lingyongtongPage'),
        meta: {
          type: 'index',
          isUnLogin: true // 是否不用登录
        },
        hidden: true
      },
      {
        path: '/youzhiRecruit',
        name: 'youzhiRecruit',
        component: () => import('@/page/ywqnHomePage/views/index/youzhiRecruit'),
        meta: {
          type: 'index',
          isUnLogin: true // 是否不用登录
        },
        hidden: true
      },
      {
        path: '/insurance',
        name: 'insurance',
        component: () => import('@/page/ywqnHomePage/views/index/insuranceAgency'),
        meta: {
          type: 'index',
          isUnLogin: true // 是否不用登录
        },
        hidden: true
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/page/ywqnHomePage/views/index/aboutUs'),
        meta: {
          type: 'index',
          isUnLogin: true // 是否不用登录
        },
        hidden: true
      },
      {
        path: '/youweiCloud',
        name: 'youweiCloud',
        component: () => import('@/page/ywqnHomePage/views/index/youweiCloud/youweiCloud'),
        meta: {
          type: 'index',
          isUnLogin: true // 是否不用登录
        },
        hidden: true
      },
      {
        path: '/parkOperation',
        name: 'parkOperation',
        component: () => import('@/page/ywqnHomePage/views/index/parkOperation/parkOperation'),
        meta: {
          type: 'index',
          isUnLogin: true // 是否不用登录
        },
        hidden: true
      }
    ]
  },
  { path: '*', redirect: '/index', hidden: true }
]

export const constantRouterMap = [
  ...baseRouter
]

export const asyncRouterMap = []

export const guards = {
  beforeEach(to, from, next) {
    next()
  }
}
