import * as home from './home'
import * as admin from './admin'

import { pageName } from '@/config'
let adminBase = '/pcb/'
const pathname = window.location.pathname.replace(/#(.*?)$/, '').split('/').filter(item => item)
const env = process.env.VUE_APP_RUNTIME
if (((env === 'production' || env === 'test') && /xkd/g.test(pathname[0]))) {
  adminBase = '/' + pathname[0] + adminBase
}
const config = {
  [pageName.home]: {
    base: {
      mode: 'history'
    },
    ...home
  },
  [pageName.admin]: {
    base: {
      mode: 'history',
      base: adminBase
    },
    ...admin
  }
}

export default {
  ...config[window.$pageName]
}
