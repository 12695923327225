<template>
  <div id="page-title" v-if="!$route.meta.hiddenTitle">
    <div class="page-title_content">
      <template v-for="item in breadCrumbs">
        <template>
          <router-link :key="item.path" class="breadCrumbs" :to="item">
            {{item.pageName}}
          </router-link>
          <span :key="item.pageName" class="jiantou-right">/</span>
        </template>
      </template>
      {{title}}
    </div>
<!--    <div v-if="breadCrumbs.length"-->
<!--         class="returnBtn" @click="goBack">-->
<!--      <div class="icon-return-c">-->
<!--      </div>-->
<!--      返回-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'breadCrumbs',
  data () {
    return {
      breadCrumbs: [], // 面包屑数据
      title: '', // 当前标题
      // 正常路由配置 看  router/index.js
      // 临时面包屑配置   参考 报名记录》通知记录  这种同路由多页面的
      // 页面上需要的配置 参考 报名记录》通知记录   isShowNoticeRecord和openNoticeRecord 方法
      tempBreadCrumbs: {
        noticeRecord: {
          title: '通知记录',
          parentPage: {
            defaultPage: [
              {
                path: '/recruit/job',
                pageName: '岗位管理'
              },
              {
                path: '/recruit/apply-record',
                pageName: '报名记录',
                urlKey: ['jobId'],
                query: {}
              }
            ]
          }
        },
        editCompanyRelev: {
          title: '编辑企业',
          currentQuery: 'comRecruitId',
          parentPage: {
            defaultPage: [
              {
                path: '/company-relev-parent/company-relev',
                pageName: '代招企业'
              },
              {
                path: '/company-relev-parent/add-company',
                pageName: '企业详情',
                urlKey: ['comRecruitId'],
                query: {}
              }
            ]
          }
        },
        editCompanyInfo: {
          title: '编辑企业',
          parentPage: {
            defaultPage: [
              {
                path: '/company-info/company-info',
                pageName: '企业资料'
              }
            ]
          }
        }
      }
    }
  },
  created () {
    this.setBreadCrumbs()
  },
  methods: {
    goBack () {
      const query = {}
      const breadCrumbs = this.breadCrumbs[this.breadCrumbs.length - 1]
      if (breadCrumbs.urlKey && breadCrumbs.urlKey.length) {
        breadCrumbs.urlKey.map(i => {
          query[i] = breadCrumbs.query[i]
        })
      }
      this.$router.push({
        path: breadCrumbs.path,
        query: query
      })
      // this.$router.go(-1)
    },
    setBreadCrumbs () {
      // 是否为临时面包屑  参考 报名记录》通知记录  这种同路由多页面的   如果是 值为   this.tempBreadCrumbs（临时面包屑配置）的key
      const tempBreadCrumbs = this.$router.currentRoute.query.tempBreadCrumbs || ''
      // parentPageKey参数控制面包屑的显示值为菜单根路由path  参考/payrollRecord/invoice路由  分别由报酬发放记录进入和发票管理进入时
      // 一般为该页面有多个面包屑展示
      // 如 报酬发放记录进入发票信息， 发票管理进入发票信息
      const parentPageKey = this.$router.currentRoute.query.parentPageKey || 'defaultPage'
      let breadCrumbs = []
      if (this.tempBreadCrumbs[tempBreadCrumbs]) { // 临时面包屑
        if (this.tempBreadCrumbs[tempBreadCrumbs].parentPage && this.tempBreadCrumbs[tempBreadCrumbs].parentPage[parentPageKey]) {
          breadCrumbs = this.tempBreadCrumbs[tempBreadCrumbs].parentPage[parentPageKey]
        }
        this.title = this.tempBreadCrumbs[tempBreadCrumbs].title
      } else { // 正常路由页面面包屑  正常路由配置 看  router/index.js
        const currentRoute = this.$router.currentRoute
        if (currentRoute.meta.parentPage && currentRoute.meta.parentPage[parentPageKey]) {
          breadCrumbs = currentRoute.meta.parentPage[parentPageKey]
        }
        if (this.$route.query.customTitleName) {
          this.title = this.$route.query.customTitleName
        } else {
          this.title = this.$router.currentRoute.meta.title
        }
      }
      this.breadCrumbs = breadCrumbs.map(item => {
        if (item.urlKey && item.urlKey.length) {
          item.urlKey.map(i => {
            if (!item.query) {
              item.query = {}
            }
            item.query[i] = this.$router.currentRoute.query[i]
          })
        }
        return item
      })
    }
  },
  watch: {
    $route () {
      this.setBreadCrumbs()
    }
  }
}
</script>

<style lang="less" scoped>
  @import "breadCrumbs.less";
</style>
