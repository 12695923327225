/**
 * Created by 郭辉 on 2018-06-27
 */
import { setting } from '@/setting'
import DictConfig from '@/utils/dictConfig'
const { baseInfo } = setting
export const hroTelephone = '020-3788-7247' // hro客服电话 广州
export const CSSTelephone = baseInfo.CSSTelephone // 众包服务客服电话 深圳
export const layoutNavData = baseInfo.layoutNavData
export const menuData = {
  recruit: {
    name: '招聘服务',
    path: '/myIndex/my-index', // 首页
    key: 'recruit', // 菜单类型
    toKey: 'recruit'
  }, // 招聘
  notice: {
    name: '消息中心',
    path: '/myIndex/my-index',
    key: 'notice',
    toKey: 'recruit'
  }, // 消息
  accountCenter: {
    name: '账号中心',
    path: '/myIndex/my-index',
    key: 'accountCenter',
    toKey: 'recruit'
  }, // 账号中心
  crowdSourcingServices: {
    name: '业务管理',
    path: '/payrollRecord/payrollRecord',
    key: 'crowdSourcingServices',
    toKey: 'crowdSourcingServices',
    otherMenu: {
      crowdSourcingServicesChannel: {
        key: 'crowdSourcingServicesChannel',
        name: '集团与渠道管理'
      },
      complianceManagement: {
        key: 'complianceManagement',
        name: '合规宝'
      }
    }
  } // 众
  // hro: {
  //   name: 'HRO服务',
  //   path: '/hroStaffManagement/hroStaffManagement',
  //   key: 'hro',
  //   toKey: 'hro'
  // }
}

// 异步任务执行状态
export const ASYNC_TASK_RESULT_STATUS = {
  FINISHED: 1,
  WAIT: -1,
  RUNNING: 0
}

// 人力资源认证审核状态
export const HR_STATUS = {
  NOT_AUTH: 0,
  SUCCESS: 1,
  AUTH_FAIL: 2,
  IN_AUTH: 3
}

const random = Math.random()
// 各个模板下载地址
export const TEMPLATE_DOWNLOAD_URL = {
  // 薪酬模板 批量修改薪酬金额
  REMUNERATION_TEMPLATE: `${baseInfo.ossUrl}/common-static/lyt/%E8%96%AA%E9%85%AC%E8%A1%A8%E6%A8%A1%E6%9D%BF.xlsx?r=${random}`,
  // hro下载签约名单
  ROSTER_HRO: `${baseInfo.ossUrl}/common-static/hro/%E7%AD%BE%E7%BA%A6%E8%A1%A8%E6%A8%A1%E6%9D%BF.xlsx?r=${random}`,
  // hro解约
  RESCIND_HRO: `${baseInfo.ossUrl}/common-static/hro/%E7%AD%BE%E7%BA%A6%E5%90%8D%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx?r=${random}`,
  // hro薪酬发放
  EMOLUMENT_HRO: `${baseInfo.ossUrl}/common-static/hro/%E8%96%AA%E9%85%AC%E8%A1%A8%E6%A8%A1%E6%9D%BF.xlsx?r=${random}`,
  // hro员工管理
  STAFF_TEMPLATE_URL_HRO: `${baseInfo.ossUrl}/common-static/hro/%E5%91%98%E5%B7%A5%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx?r=${random}`,
  // 快速分包
  SUBPACKAGE: `${baseInfo.ossUrl}/common-static/%E6%8A%A5%E9%85%AC%E6%A8%A1%E6%9D%BF.xlsx?r=${random}`,
  // 新劳务所得报酬模板.xlsx
  SUBPACKAGE_LABOUR: `${baseInfo.ossUrl}/common-static/b-saas/%E6%96%B0%E5%8A%B3%E5%8A%A1%E6%89%80%E5%BE%97%E6%8A%A5%E9%85%AC%E6%A8%A1%E6%9D%BF.xlsx?r=${random}`,
  // 花名册 外包 or 众包
  ROSTER_OUTSOURCING: `${baseInfo.ossUrl}/common-static/manage/%E8%8A%B1%E5%90%8D%E5%86%8C%E6%A8%A1%E6%9D%BF.xlsx?r=${random}`,
  // 众包发起签约
  SIGN_OUTSOURCING: `${baseInfo.ossUrl}/common-static/manage/%E7%AD%BE%E7%BA%A6%E4%BA%BA%E5%91%98%E5%90%8D%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx?r=${random}`
}


export const employOrderStatus = {
  Initialize: 10, // 初始生成工单
  // ConfirmPaid: 12, // 系统到账确认中
  // Unpaid: 14, // 未到账
  // PartPaid: 16, // 部分到账
  // Paid: 18, // 已确认到账
  // AuditFail: 20, // 人工审核确认失败
  // AuditSuccess: 22, // 人工审核通过(客户待确认)
  Dispensing: 24, // 发放中(客户已确认)
  DispenseFail: 26, // 发放失败
  DispensePartSuccess: 28, // 部分发放成功
  DispenseSuccess: 30, // 全部发放完成
  SuccessFinish: 32, // 成功完单
  FailFinish: 34 // 废弃工单
}
export const authStatus = {
  TRANSFER_REFILL_STATUS: {
    NOT_AUTH: 0,
    SUCCESS: 1,
    NEED_AGAIN: 2
  },
  C_FOUR_FACTORS_STATUS: {
    NOT_AUTH: 0,
    SUCCESS: 1
  },
  P_THREE_FACTOR_STATUS: {
    NOT_AUTH: 0,
    SUCCESS: 1
  },
  TRANSFER_STATUS: {
    TRANSFER_FAIL: 4,
    IN_THE_TRANSFER: 1,
    TRANSFER_SUCCESS: 2
  },
  CONTRACT_STATUS: {
    INVALID: 0,
    VALID: 1
  },
  ZB_SIGN: {
    INVALID: 0,
    VALID: 1
  }
}


// 高德kye
export const GDKye = baseInfo.GDKye // js
// export const GDKyeWeb = '27b6039d0e2fc1701010b7ac7e39a010' // web


// 环境判断
export const BASEURL = process.env.VUE_APP_BASE_URL

const H5_SIGN_URL = {
  PRO: 'https://s.youweiwork.com/h5-act/index.html#/signLogin', // 正式环境签署链接,
  TEST: 'https://s.youweiwork.com/h5-act-test/index.html#/signLogin' // 测试环境签署链接
}

const envURl = (URL) => process.env.VUE_APP_RUNTIME !== 'production' ? URL.TEST : URL.PRO

export const h5SignUrl = envURl(H5_SIGN_URL)
/*
  公共请求状态
 */
export const statusCode = {
  OK: '000000',
  FAIL: '999999',
  LOGIN_EXPIRED: '000001',
  NOT_AUTHENTICATED: '993001',
  IMG_CODE: '991120',
  INSUFFICIENT_BALANCE: '999003', // 余额不足
  BUY_INTERVIEW: '994017', // 需要购买直面
  NOT_FREE_JOB: '994016', // 不是免费岗位
  ABNORMAL_ACCOUNT: '993002', // 账号异常
  EXCEL_ERR: '990202', // EXCEL解析错误
  LOADING_CHECK_CARD: '990201', // 等待验卡中
  REMIND_C_USER_SMS: '990203', // 提醒c端用户签约短信 次数限制
  ACCOUNT_STATEMENT_FAIL: '990204', // 结单失败
  B_EMPLOY_ZB_NOT_SIGN: '990309',
  LICENSE_OCR_FAIL: '998008',
  LICENSE_OCR_LIMIT: '998011',
  LACK_SUPPLIER_INFO: '993008', // 缺少供应商信息
  RELEASE_LACK_SUPPLIER_INFO: '990211', // 发任务缺少供应商信息
  MULTI_SUPPLIER: '995003', // 开发票时选择了多个供应商的账单
  UN_SET_MONEY: '990210', // 去发放佣金时未设置佣金
  NEED_PASSWORD: '990003', // 众包下发佣金需要支付密码
  OLD_PASSWORD_ERR: '999100', // 原密码错误
  PASSWORD_FREEZE: '990002', // 支付密码被冻结
  NOT_THE_REQUIRED_TEMPLATE: '990021' // 不是指定的模板
}

export const salaryEpibolicRequestCode = {
  TRANSFER_FAIL: '30502002',
  TRANSFER_FAIL_TOO_MUCH: '30502003',
  COMPANY_AUTH_NO_NAME: '30500113',
  COMPANY_AUTH_NOT_PASS: '30500001',
  BANK_ERR: '30500101',
  P_THREE_FACTOR_FAIL: '30504001'
}

/**
 * 众包服务任务状态
 * @type {{WAITING_AUDIT: number, AUDIT_SUCCESS: number, AUDIT_FAIL: number, FINISH: number, CLOSE: number}}
 */
export const TASK_STATUS = {
  WAITING_AUDIT: 10, // 发布-待审核
  PENDING: 88, // 发布-待处理(前端定的， 后端existPendingNum: true,)
  AUDIT_SUCCESS: 12, // 审核成功-进行中
  AUDIT_FAIL: 14, // 审核失败
  FINISH: 16, // 任务完成
  CLOSE: 99 // 关闭
}
// 发票状态
export const INV_STATUS = new DictConfig([
  {
    key: 'pending',
    value: 0,
    name: '待申请'
  },
  {
    key: 'applied',
    value: 1,
    name: '已申请'
  },
  {
    key: 'out',
    value: 5,
    name: '已开票'
  },
  {
    key: 'post',
    value: 6,
    name: '已寄出'
  }
])
/**
 * 对应一个c端用户任务状态
 * @type {{APPLY: number, PASS: number, UNSUITED: number, COMPLETE: number, REJECT: number, VERIFIED: number, DISPENSING: number, FINISH: number, DISPENSE_FAIL: number}}
 */
export const TAKE_ORDER_FORM_TASK_STATUS = {
  APPLY: 0, // 提交申请
  PASS: 2, // 确认通过 - C端任务进行中
  UNSUITED: 3, // 不合适 - 任务报名
  COMPLETE: 5, // 完成任务 - 等待B端确认
  REJECT: 7, // 驳回 - 完成任务确认
  VERIFIED: 8, // 确认完成任务
  DISPENSING: 9, // 发放中
  FINISH: 10, // 发放完成
  DISPENSE_FAIL: 11 // 发放佣金失败
}
export const ORDER_ENTRUSTED = {
  IS_ENTRUSTED: 1,
  NOT_ENTRUSTED: null
}
export const PAGE_TASK_STATUS = {
  Apply: {
    status: [TAKE_ORDER_FORM_TASK_STATUS.APPLY],
    name: 'Apply'
  },
  Fail: {
    status: [TAKE_ORDER_FORM_TASK_STATUS.UNSUITED],
    name: 'Fail'
  },
  Pass: {
    status: [
      TAKE_ORDER_FORM_TASK_STATUS.PASS,
      TAKE_ORDER_FORM_TASK_STATUS.COMPLETE,
      TAKE_ORDER_FORM_TASK_STATUS.REJECT,
      TAKE_ORDER_FORM_TASK_STATUS.VERIFIED,
      TAKE_ORDER_FORM_TASK_STATUS.DISPENSING,
      TAKE_ORDER_FORM_TASK_STATUS.FINISH,
      TAKE_ORDER_FORM_TASK_STATUS.DISPENSE_FAIL
    ],
    name: 'Pass'
  },
  TaskResultAudit: {
    status: [
      TAKE_ORDER_FORM_TASK_STATUS.COMPLETE
    ],
    name: 'TaskResultAudit'
  },
  TaskResultComplete: {
    status: [
      TAKE_ORDER_FORM_TASK_STATUS.VERIFIED,
      TAKE_ORDER_FORM_TASK_STATUS.DISPENSING,
      TAKE_ORDER_FORM_TASK_STATUS.FINISH,
      TAKE_ORDER_FORM_TASK_STATUS.DISPENSE_FAIL
    ],
    name: 'TaskResultComplete'
  },
  TaskResultFail: {
    status: [
      TAKE_ORDER_FORM_TASK_STATUS.REJECT
    ],
    name: 'TaskResultFail'
  },
  WaitDispense: {
    status: [TAKE_ORDER_FORM_TASK_STATUS.VERIFIED],
    name: 'WaitDispense'
  },
  Dispensing: {
    status: [TAKE_ORDER_FORM_TASK_STATUS.DISPENSING],
    name: 'Dispensing'
  },
  DispenseSuccess: {
    status: [
      TAKE_ORDER_FORM_TASK_STATUS.FINISH,
      TAKE_ORDER_FORM_TASK_STATUS.DISPENSE_FAIL
    ],
    name: 'DispenseSuccess'
  }

  // applyCount
  // failCount
  // passCount
  // taskResultAuditCount
  // taskResultCompleteCount
  // taskResultFailCount
  // waitDispenseCount
  // dispensingCount
  // dispenseSuccessCount
}

export const PAYMENT_WAGES_STATUS = {
  WAITING: 0,
  DOING: 1,
  FAIL: 2,
  SUCCESS: 3
}
/*
  用户角色
 */
export const rolesType = {
  DEFAULT: 0,
  AGENT: 1, // 人力
  EMPLOYER: 2 // 商户
}
/*
公用正则
 */
// export const validateReg = {
//   required:/[\S]+/, //空
//   phone: /^1\d{10}$/, // 手机号
//   identity: /(^\d{15}$)|(^\d{17}(x|X|\d)$)/,  // 身份证
//   email: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/  // 邮箱
// }

export const FAST_PACKAGE = [
  {
    value: 0,
    key: 'CLOSE',
    name: '关闭'
  },
  {
    value: 1,
    key: 'OPEN',
    name: '开启'
  }
]

export const FAST_PACKAGE_MAP = new DictConfig(FAST_PACKAGE)

export const validContractMap = [
  {
    key: 'NONE',
    name: '无效',
    value: 0
  },
  {
    key: 'VALID',
    name: '有效',
    value: 1
  }
]

export const VALID_CONTRACT_MAP = new DictConfig(validContractMap)


export const BIZ_TYPE = {
  CROWDSOURCE: {
    label: '众包合作',
    type: 2 // 列表查询状态
  },
  PART_TIME: {
    label: '非全合作',
    type: 6 // 列表查询状态
  }
}

export const TEMPLATE_TYPE_MAP = {
  PART_TIME_UNIVERSAL: 0, // 非全合同
  PART_TIME_RESIGN: 1 // 离职协议
}

export const TASK_TARGET_KEY = {
  // 导入花名册
  ROSTER_INSERT: 'ROSTER_INSERT',
  // 下载签约情况
  DOWNLOAD_ROSTER_SIGN_INFO: 'DOWNLOAD_ROSTER_SIGN_INFO',
  // 导出电子回单
  EXPORT_VOUCHER: 'EXPORT_VOUCHER',
  // 佣金表解析结果
  B_SAL_XLS_IMPORT: 'B_SAL_XLS_IMPORT',
  // 佣金表解析结果
  LABOR_PRE_IMPORT: 'LABOR_PRE_IMPORT',
  // 下发佣金异步计算
  LABOR_COMPUTE_FEE: 'LABOR_COMPUTE_FEE',
  // HRO发薪，薪酬表--预检查
  HRO_EXCEL_PRE_CHECK: 'HRO_EXCEL_PRE_CHECK',
  // 众包服务-任务管理-任务进度-导出人员表单
  EMPLOY_TASK_APPLY: 'EMPLOY_TASK_APPLY',
  // HRO 薪酬发放--薪酬表导入
  HRO_EXCEL_INSERT_DB: 'HRO_EXCEL_INSERT_DB',
  // 修改佣金金额 任务
  PUB_EXCEL_IMPORT: 'PUB_EXCEL_IMPORT',
  // 应发模式计算费用
  PAYABLE_COMPUTE_FEE: 'PAYABLE_COMPUTE_FEE',
  // 众包实发模式计算费用
  SALARY_COMPUTE_FEE: 'SALARY_COMPUTE_FEE'
}
export const DISPENSE_STATUS = new DictConfig([
  {
    value: 0,
    name: '待下发',
    key: 'WAIT',
    style: 'color:#FF9900'
  },
  {
    key: 'IN_DISPENSE',
    value: 1,
    name: '下发中',
    style: 'color:#FF9900'
  },
  {
    key: 'DISPENSE_FAIL',
    value: 2,
    name: '下发失败',
    style: 'color:#F56C6C'
  },
  {
    key: 'DISPENSE_SUCCESS',
    value: 3,
    name: '下发成功',
    style: 'color:#C0C4CC'
  },
  {
    key: 'CANCEL_DISPENSE',
    value: 4,
    name: '取消发佣',
    style: 'color:#666666'
  }
])

export const NO_CRIME_FLAG = new DictConfig([
  {
    key: 'NO',
    name: '否',
    value: 0
  },
  {
    key: 'YES',
    name: '是',
    value: 1
  }
])

// 结算类型
export const SUP_BIZ_SCOPE = new DictConfig([
  {
    name: '众包结算-实发',
    value: 1,
    key: 'crowdSourcing',
    tranAmountTitle: '需发金额',
    taxesKey: 'totalFee',
    computeTimeKey: 'salaryComputeTime',
    asyncComputeKey: 'salaryComputeFeeAsync',
    target: TASK_TARGET_KEY.SALARY_COMPUTE_FEE,
    computeDialog: {
      payCheckKey: 'totalSalary',
      payCheckTitleKey: '下发佣金总额（税后）'
    }
  },
  {
    name: '众包结算-应发',
    value: 3,
    key: 'payable',
    taxesKey: 'totalPayableFee',
    tranAmountTitle: '实发金额',
    computeTimeKey: 'payableComputeTime',
    asyncComputeKey: 'payableComputeFeeAsync',
    target: TASK_TARGET_KEY.PAYABLE_COMPUTE_FEE,
    computeDialog: {
      payCheckKey: 'payableAmount',
      payCheckTitleKey: '下发佣金总额（税前）'
    }
  },
  {
    name: '劳务报酬结算',
    value: 2,
    key: 'remuneration',
    taxesKey: 'totalLaborFee',
    tranAmountTitle: '需发金额',
    computeTimeKey: 'laborComputeTime',
    asyncComputeKey: 'laborComputeFeeAsync',
    target: TASK_TARGET_KEY.LABOR_COMPUTE_FEE,
    computeDialog: {
      payCheckKey: 'payableAmount',
      payCheckTitleKey: '下发佣金总额（税后）'
    }
  }
])

export const FEATURE_TYPE = new DictConfig([
  {
    key: 'none',
    value: 0,
    name: '普通任务',
    regChannel: 'pc-tasklist'
  },
  {
    key: 'blueMoon',
    value: 1,
    name: '蓝月亮任务',
    regChannel: 'bluemoon'
  },
  {
    key: 'blueMoonSpecial',
    value: 2,
    name: '蓝月亮特殊任务',
    regChannel: 'bluemoon'
  },
  {
    key: 'liby',
    value: 3,
    name: '立白任务',
    regChannel: 'liby'
  }
])

export const BIZ_SCENE_IDS = new DictConfig([
  {
    key: 'exportDispense',
    value: 5,
    name: '结算单详情-下载发佣明细'
  },
  {
    key: 'exportErrPeople',
    value: 6,
    name: '快速分包-下载模板错误名单'
  },
  {
    key: 'exportEmployee',
    value: 7,
    name: '认证管理-下载名单'
  },
  {
    key: 'exportOrderList',
    value: 9,
    name: '结算单管理-导出结算单'
  },
  {
    key: 'channelCommissionRecord',
    value: 10,
    name: '却道发佣记录-导出发佣记录'
  }
])
