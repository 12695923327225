/**
 *@desc validate字段统一配置
 */
export const validateRules = {
  name: [ // 企业名
    { required: true, max: 50, message: '长度在 50 个字符内', trigger: 'blur' }
  ],
  orgCode: [ // 营业执照号
    { required: true, trigger: 'blur', message: '请输入营业执照代码！' },
    { trigger: 'blur', pattern: /^[0-9a-zA-Z]{15}([0-9a-zA-Z]{3,5})?$/, message: '请输入正确的营业执照代码！' }
  ],
  legalRepName: [{ required: true, message: '请输入法人姓名！' }], // 法人姓名
  legalRepIdNo: [
    { required: true, message: '请输入法人身份证！' },
    { pattern: /(^\d{15}$)|(^\d{17}(x|X|\d)$)/, message: '请填写正确的身份证号码' }
  ], // 法人身份证
  cardNo: [
    { required: true, message: '请输入企业对公账户！' },
    { pattern: /^[0-9]*$/, message: '请输入正确的企业对公账户！' }
  ], // 对公账户
  bank: [{ required: true, message: '请输入企业开户行名称！' }], // 开户行名称
  city: [{ trigger: 'change', type: 'array', required: true, message: '请选择开户支行所在地' }], // 对公账号开户行所在城市名称
  subbranch: [{ required: true, message: '请输入企业开户行支行名称！' }], // 开户支行名称
  amount: [ // 校验金额
    { required: true, message: '请填写您收到的款项金额' },
    { pattern: /^((([1-9]+(\d+)?)(\.\d{1,2})?)|(0\.\d{1,2}))$/, message: '请填写正确的金额格式，最多支持两位小数' }
  ],
  idNo: [
    { required: true, message: '请填写您的身份证号码' },
    { pattern: /(^\d{15}$)|(^\d{17}(x|X|\d)$)/, message: '请填写正确的身份证号码' }
  ],
  mobileNo: [
    { required: true, message: '请填写您的手机号码' },
    { pattern: /^1[0-9]{10}$/, message: '请填写正确手机号码' }
  ],
  authCode: [
    { required: true, message: '请填写验证码' }
  ],
  invoiceItems: [
    { required: true, message: '请填写开票名目' }
  ],
  comNowAddr: [
    { required: true, message: '请填写公司所在地址' }
  ],
  comRegAddr: [
    { required: true, message: '请填写公司注册地址' }
  ],
  inDate: [
    { required: true, message: '请选择合同有效期' }
  ],
  linkMan: [
    { required: true, message: '请输入联系人姓名！' }
  ],
  linkPhone: [
    { required: true, message: '请填写联系人手机号码' },
    { pattern: /^1[0-9]{10}$/, message: '请填写正确手机号码' }
  ]
}
