/**
 * Created by 郭辉 on 2018-06-27
 */
import request from '@/utils/request'

/**
 * 获取企业资料
 */
export const getCompanyInfo = data => request.get('/api/console/company/get_com_info', { params: data })

