import request from '@/utils/request'
import { salaryEpibolicRequestCode, statusCode } from 'api/config'


/**
 * 对公账户验证金额提交
 */
export const verifyRandomAmount = data => request.post(
  '/api/console/esign/company/bank/verifyRandomAmount',
  data,
  {
    headers: {
      ignoreCode: [
        salaryEpibolicRequestCode.TRANSFER_FAIL_TOO_MUCH,
        salaryEpibolicRequestCode.TRANSFER_FAIL
      ]
    }
  }
)

/**
 * 对公账户验证金额提交
 */
export const agentAuth = data => request.post('/api/console/esign/personal/auth', data)

/**
 * 保存并预览合同
 */
export const saveAndPreviewContract = data => request.post('/api/console/esign/contract/save', data)

/**
 * 确定签署
 */
export const contractSign = data => request.post('/api/console/esign/contract/sign', data)


/**
 * 经办人认证发送短信
 */
export const personalSendMsg = data =>
  request.post('/api/console/esign/personal/sendMsg', data,
    { headers: { ignoreCode: [salaryEpibolicRequestCode.P_THREE_FACTOR_FAIL] } })



/**
 * 拉取工单信息
 */
export const getOrderInfo = (data) => request.get('/api/console/employ/orderInfo', { params: data })


/**
 * 拉取薪酬外包的企业认证信息
 */
export const getSalaryEpibolicComInfo = () => request.get('/api/console/esign/company/info')

/**
 * 删除工单
 */
export const delOrder = data => request.post('/api/console/employ/delOrder', data)

/**
 * 工单列表
 */
export const orderList = (data) => request.get('/api/console/employ/newOrderList', { params: data })

/**
 * 拉取发票信息
 */
export const getReceiptInfo = data => request.get('/api/console/employ/receiptInfo', { params: data })

/**
 * 获取可开发票的已完成工单
 */
export const getInvoiceList = params =>
  request.get('/api/console/invoice/doableList', { params })

/**
 * 申请开发票
 */
export const applyInvoice = data => request.post(
  '/api/console/invoice/make',
  data,
  {
    headers: {
      ignoreCode: [
        statusCode.MULTI_SUPPLIER
      ]
    }
  }
)

/**
 * 获取工单详情
 */
export const getOrderDetail = data => request.get('/api/console/employ/orderDetail', { params: data })

/**
 * 获取工单列表 （发票管理）
 */
export const getEmpOrderList = data => request.get('/api/console/empOrder/list/status', { params: data })

/**
 * 发票申请记录 （发票管理）
 */
export const getInvoiceApplyList = data => request.get('/api/console/invoice/list', { params: data })

/**
 * 获取发票关联订单 （发票管理）
 */
export const getInvoiceRelevanceOrder = data => request.get('/api/console/invoice/belongOrder', { params: data })

/**
 * 获取发票收件人信息
 */
export const getInvoiceInfo = data => request.get('/api/console/comExtra/queryByComId', { params: data })


/**
 * 获取发票类目
 */
export const getInvoiceType = data => request.get('/api/console/invoice/type', { params: data })

/**
 * 获取服务商的发票类目
 */
export const getSupUserInvoiceType = data => request.get('/api/console/empOrder/queryInvoiceTypesByEmpOrderId', { params: data })

/**
 * 获取已开发票总额 （发票管理）
 */
export const getInvoiceSumTotal = data => request.get('/api/console/invoice/sumTotal', { params: data })

/**
 * 获取薪酬外包-发放统计
 */
export const getDispenseFlow = data => request.get('/api/console/employ/dispenseFlow', { params: data })

// 众包佣金发放--------------------------------

/**
 * 佣金发放（全部）
 */
export const dispenseWhole = data => request.post(
  '/api/console/empOrder/dispenseWhole',
  data,
  {
    headers: {
      ignoreCode: [
        statusCode.NEED_PASSWORD,
        statusCode.PASSWORD_FREEZE
      ]
    }
  }
)

/**
 * 佣金重新发放（全部）
 */
export const dispenseWholeAgain = data => request.post('/api/console/empOrder/dispenseWholeAgain', data)

/**
 * 佣金重新发放（单个）
 */
export const dispenseSingly = data => request.post('/api/console/empOrder/dispenseSingly', data)

/**
 * 修改下发失败的用户信息
 */
export const updateEmpInfo = data => request.post('/api/console/empOrder/updateEmpInfo', data)

/**
 * 查询下发详情列表
 */
export const queryDispenseList = data => request.get('/api/console/empOrder/queryDispenseList', { params: data })

/**
 * 查询下发失败但还未修改信息的用户数
 */
export const unmodifiedNum = data => request.get('/api/console/empOrder/unmodifiedNum', { params: data })

// 众包佣金发放--------------------------------end

/**
 * 获取发佣退费名单
 */
export const getCancelDispenseList = params =>
  request.get('/api/console/empOrderDetail/cancelDispenseList', { params })

/**
 * 取消发佣
 */
export const cancelDispense = params =>
  request.post('/api/console/empOrder/cancelDispense', params)

/**
 * 获取已配置开票类目
 */
export const queryInvoiceTypes = data =>
  request.get('/api/console/invoice/queryInvoiceTypesByComIdAndSupUserId', { params: data })


/**
 * 获取任务类型
 */
export const queryComTaskInvoice = data =>
  request.get('/api/console/invoice/queryComTaskInvoice', { params: data })
/**
 * 开票设置保存
 */
export const autoApplyInvoiceConfig = params =>
  request.post('/api/console/invoice/autoApplyInvoiceConfig', params)
